import { Card, Table } from 'react-bootstrap';
import MaticLogo from '../polygon_matic.svg';

export default (props) => {

    if(!props.prizes) { return null }

    let prizeParser = (index, fixed) => {

      if(props.web3 && props.web3.utils) {

        const toFixed = fixed ?? 4

        const prize = props.prizes[index]
        const prizeEther = props.web3.utils.fromWei(prize, 'ether')
        return Number(prizeEther).toFixed(toFixed)
      }

      return 0
    }

    return (
        <Card className="border shadow p-3 mb-1 bg-light rounded" style={{ width: '100%' }}>
        <Card.Text className='justify-content-start align-items-center rounded p-1 bg-dark text-white'>
            🏆 Next guess' prize table
        </Card.Text>
        <Table striped bordered hover size="sm">
            <thead>
            <tr>
                <th>Total Matches</th>
                <th>Prize</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td><h3>6</h3></td>
                <td className='p-1'><h3>{prizeParser(6, 9)} <img height="24px" src={MaticLogo} alt="Matic Logo" /></h3></td>
            </tr>
            <tr>
                <td><h3>5</h3></td>
                <td><h3>{prizeParser(5, 7)} <img  height="22px" src={MaticLogo} alt="Matic Logo" /></h3></td>
            </tr>
            <tr>
                <td><h4>4</h4></td>
                <td><h4>{prizeParser(4, 6)} <img height="18px" src={MaticLogo} alt="Matic Logo" /></h4></td>
            </tr>
            <tr>
                <td><h5>3</h5></td>
                <td><h5>{prizeParser(3, 5)} <img height="16px" src={MaticLogo} alt="Matic Logo" /></h5></td>
            </tr>
            <tr>
                <td><h6>2</h6></td>
                <td><h6>{prizeParser(2, 5)} <img height="14px" src={MaticLogo} alt="Matic Logo" /></h6></td>
            </tr>
            <tr>
                <td>1</td>
                <td>{prizeParser(1, 4)} <img height="10px" src={MaticLogo} alt="Matic Logo" /></td>
            </tr>
            <tr>
                <td>0</td>
                <td>{prizeParser(0, 3)} <img height="10px" src={MaticLogo} alt="Matic Logo" /></td>
            </tr>
            </tbody>
        </Table>
      </Card>
    );
}