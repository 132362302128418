import { Alert, Row, Col, Stack } from 'react-bootstrap';
import { Player } from '@lottiefiles/react-lottie-player';
import LoadingAnimation from "../../animations/loading.json"

export default (props) => {

    return (<Alert className='my-1' show={props.betConfirming} variant={'secondary'}>
                <Stack direction="horizontal" gap={3}>
                    <Player
                        autoplay
                        loop
                        src={LoadingAnimation}
                        style={{ height: '100px', width: '100px' }} />
                    <h4 className='text-dark'>Loading </h4><h5><a className='text-info' href={props.transactionExplorer} target="blank">Check your transaction</a></h5>
                </Stack>
            </Alert>)
}