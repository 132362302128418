
import { Button, Row, Stack} from 'react-bootstrap';

export default () => {

    return (<Row className="p-4">
                <Stack direction="horizontal" gap={3}>
                <div className='text-light'>Contacts:</div>
                <a href='https://discord.gg/mrtz2pCrVt'>
                <Button size="sm" variant="light"><span translate="no">Discord</span></Button>
                </a>
                </Stack>
            </Row>)
}