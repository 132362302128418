import WalletConnectProvider from "@walletconnect/web3-provider"
import Web3 from "web3"

export default class Wallet {

    web3 = null

    get connector() { return this.provider.connector }
    get connected() { return this.provider.connected }
    get icon() { return this.connector.peerMeta.icons[0] }
    get name() { return this.connector.peerMeta.name }
    get address() { return this.connector.accounts[0] }

    constructor(chainId, rpcURL) {

        this.chainId = chainId
        this.rpc = {}
        this.rpc[chainId] = rpcURL

        this.onDisconnect = console.log
        this.onConnect = console.log
        this.onSessionUpdate = console.log
        this.onConnectionFailure = console.log

        this.makeWeb3()
    }

    makeWeb3 = () => {

        const bridge = "https://bridge.walletconnect.org";
        this.provider = new WalletConnectProvider({rpc: this.rpc, bridge})
        this.web3 = new Web3(this.provider)

        let storage = JSON.parse(localStorage.getItem("walletconnect"))
        if(storage && storage.connected) {

            this.connect()
        }
    }

    connect = () => {

        const connector = this.provider.connector

        connector.on("connect", this.onConnect)
        connector.on("session_update", this.onSessionUpdate)
        connector.on("disconnect", this.onDisconnect)

        this.provider.enable().catch((error) => { 

            this.disconnect()
            this.makeWeb3()
        })

        if(connector.connected) {
            this.onConnect()
        }
    }

    disconnect = () => {

        this.provider.close()
    }
}