import { Button, Alert, Stack } from 'react-bootstrap';
import { Player } from '@lottiefiles/react-lottie-player';

import WinAnimation from "../../animations/win.json"
import EmptyAnimation from "../../animations/empty.json"

import MaticLogo from '../../polygon_matic.svg';

export default (props) => {

    let win = props.totalWin > 0 ? (
        <>

        <img  height="40px" src={MaticLogo} alt="Matic Logo" />
        <h1>You received </h1>
        <h2>{props.totalWin}</h2>
        </>
    ) : (
        <p>You didn't earn anything. Try Again!</p>
    )

    let guessesText = (<><b>{props.totalMatched}</b> digits</>)

    if(props.totalMatched == 1){

        guessesText = <><b>{props.totalMatched}</b> digit</>
    }

    return(<Alert className='my-1' 
            onClose={props.onClose}
            show={props.betConfirmed} 
            variant={'success'} 
            dismissible>
                <Stack gap={2} >
                    {win}
                    <Player
                        autoplay
                        loop
                        src={ props.totalWin > 0 ? WinAnimation : EmptyAnimation}
                        style={{ height: '100px', width: '150px' }}
                    />
                    <p>You matched {guessesText} with the hash <b>{props.guesserHash}</b> over the contract hash <b>{props.blockHash}</b></p>  
                </Stack>
            </Alert>)
    return (<Alert show={props.betConfirmed} 
        variant={'success'} 
        onClose={props.onClose}
        dismissible>
        <Alert.Heading>Hash Guess Result 🕵️</Alert.Heading>
        <p>Your hash guess: <b>{props.guesserHash}</b>  <Button variant="light" size="sm" onClick={props.onGuessAgain}>
                🔄
            </Button></p>
        <p>Contract hash: <b>{props.blockHash}</b></p>
        <p>You matched <b>{props.totalMatched}</b> digits</p>
        <p>{ props.totalWin > 0 ? "You received "+props.totalWin+" matic 💎" : "You didn't earn it 😅. Try again!" }</p>
        <Player
            autoplay
            loop
            src={ props.totalWin > 0 ? WinAnimation : EmptyAnimation}
            style={{ height: '150px', width: '150px' }}
        />
        <p><Alert.Link href={props.transactionExplorer} target="blank">Check your transaction.</Alert.Link></p>
        <p><Alert.Link href={'data:' + "text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(props.receipt))} 
                        download={"receipt_"+props.receipt.transactionHash+".json"}>Download your transaction receipt.</Alert.Link></p>
        </Alert>)
}