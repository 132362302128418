
import { Row, Card } from 'react-bootstrap';
import HashGuessersBanner from '../images/banner.png'

export default (props) => {

    return (
            <Card id={props.id} className="border shadow p-3 mb-1 bg-light rounded mx-auto">
                <Row><img src={HashGuessersBanner} width="150px" /></Row>
            </Card>
            )
}