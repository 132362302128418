import { Row, Col, Button } from 'react-bootstrap';

export default (props) => {

    return (<Row className='KeyboardRow mx-auto bg-dark px-1 py-2 rounded'>
                {
                props.allAvailableDigits.map((digit, index) => { 
                    return <Col className='d-grid gap-1 my-1' xs={3} key={index.toString() } >
                            <Button size="lg" variant="light" onClick={() => {
                                const selectedValues = props.selectedValues
                                if(selectedValues.length < props.maxSelectedValues) {
                                    props.onValue(selectedValues + digit)
                                }
                            }}>{digit}</Button> 
                            </Col>
                })
                }
                <Col className='d-grid gap-2 my-1' xs={3}  key="back-button" >
                    <Button size="lg" variant="light" onClick={() => {
                        let selectedValues = props.selectedValues.split("").splice(0, props.selectedValues.length-1).join("")
                        props.onValue(selectedValues)
                    }}>⌫</Button> 
                </Col>
                <Col className='d-grid gap-2 my-1' xs={3}  key="clear-button" >
                    <Button size="lg" variant="light" onClick={() => {
                        props.onValue("")
                    }}>🆇</Button> 
                </Col>
                <Col className='d-grid gap-2 my-1 rounded' xs={6}>
                    <Button size="sm" variant="light" disabled={props.selectedValues.length >= props.maxSelectedValues} onClick={() => {
                    
                    let tempAllNumbersShuffled = props.selectedValues
                    for(let i = props.selectedValues.length; i < props.maxSelectedValues; i++) {
                        tempAllNumbersShuffled += props.allAvailableDigits.concat()
                        .filter(item => !props.selectedValues.includes(item))
                        .sort(() => .5 - Math.random())[0]
                    }

                    props.onValue(tempAllNumbersShuffled)
                    props.onShuffle(props.maxSelectedValues-props.selectedValues.length)

                    }} >
                        Select randomly
                    </Button>
                </Col>
            </Row>)
}