import './App.css';

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setUserProperties, setUserId } from "firebase/analytics";

import { Container } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';

import HashGuessers from './contract/HashGuessers';
import RulesView from './views/RulesView';
import HowItWorksView from './views/HowItWorksView';
import GuessEventsView from './views/GuessEventsView';
import PrizesTableView from './views/PrizesTableView';
import ConfirmationAlertView from './views/alerts/ConfirmationAlertView';
import ProcessingAlertView from './views/alerts/ProcessingAlertView';
import FailureAlertView from './views/alerts/FailureAlertView';
import ConnectWalletRowView from './views/ConnectWalletRowView';
import MaxPrizeRowView from './views/MaxPrizeRowView';
import ContactRowView from './views/ContactRowView';
import SelectedDigitsView from './views/SelectedDigitsView';
import KeyBoardView from './views/KeyBoardView';
import ActionButtonsView from './views/ActionButtonsView';
import Wallet from './wallet/Wallet';

const MAX_SELECT_NUMBERS = 6

const firebaseConfig = {
  apiKey: "AIzaSyCGf1MihOo-zEH7QhqRmhfSnJBt3wtOepU",
  authDomain: "hashguessers.firebaseapp.com",
  projectId: "hashguessers",
  storageBucket: "hashguessers.appspot.com",
  messagingSenderId: "754769728749",
  appId: "1:754769728749:web:94453266f41d34e91028e4",
  measurementId: "G-C15THYDCDW"
};

const trackEvent = (eventName, object) => {

  logEvent(analytics, eventName, object);
}

const switchOrAddChain = async (param) => {

  trackEvent("switch_or_add_chain")
  await window.ethereum.request({ method: 'wallet_addEthereumChain', params:[param] })
}

let web3 = undefined
let wallet = undefined
let hashGuessers = undefined
let analytics = undefined

function App(props) {

  const [betConfirming, setBetConfirming] = useState(false)
  const [betConfirmed, setBetConfirmed] = useState(false)
  const [betFailed, setBetFailed] = useState(false)
  const [allAvailableDigits, setAllNumbers] = useState(["0","1","2","3","4","5","6","7","8","9","a","b","c","d","e","f"])
  const [selectedValues, setSelectedValues] = useState("");
  const [walletConnected, setWalletConnected] = useState(false)
  const [walletIcon, setWalletIcon] = useState(null)
  const [walletName, setWalletName] = useState("")
  const [fromAddress, setFromAddress] = useState(null)
  const [receipt, setReceipt] = useState({})
  const [transactionExplorer, setTransactionExplorer] = useState("#")
  const [totalPrize, setTotalPrize] = useState("")
  const [prizes, setPrizes] = useState(null)
  const [contractAddress, setContractAddress] = useState(props.contractAddress)
  const [blockExplorer, setBlockExplorer] = useState(props.chainParam.blockExplorerUrls[0])
  const [events, setEvents] = useState(null)

  const [totalMatched, setTotalMatched] = useState(0)
  const [blockHash, setBlockHash] = useState("")
  const [guesserHash, setGuesserHash] = useState("")
  const [totalWin, setTotalWin] = useState(0)

  const guess = async (walletAddress) => {

    trackEvent("start_guess", {again: false})
    hashGuessers.guess(selectedValues, walletAddress)
  }

  const onWalletConnected = (error, payload) => {

    if (error) { return }

    let walletAddress = wallet.address

    setWalletConnected(true)
    setWalletIcon(wallet.icon)
    setWalletName(wallet.name)
    setFromAddress(walletAddress) 
    hashGuessers.initialize(walletAddress) 
    setUserId(analytics, walletAddress)
    trackEvent("wallet_connected", {walletName: wallet.name, chainId: props.chainId})
  }

  const bindHashGuessersEvents = () => {
    
    hashGuessers.onProcessing = (transactionHash) => {

      setTransactionExplorer(blockExplorer+"tx/"+transactionHash)
      setBetConfirming(true)
      setBetConfirmed(false)
      window.scrollTo(0, 0);
      trackEvent("guess_confirming")
    }

    hashGuessers.onError = (error) => {
      
      setBetConfirming(false)
      setBetConfirmed(false)
      setBetFailed(true)
      window.scrollTo(0, 0)
      trackEvent("guess_error")
    }

    hashGuessers.onCompleted = (receipt) => {

      setReceipt(receipt)
      setBetConfirmed(true)
      setBetConfirming(false)
      window.scrollTo(0, 0)
      trackEvent("guess_completed")
      let walletAddress = wallet.address
      window.callPixel(walletAddress);
    }

    hashGuessers.onGuessResultUpdated = (values) => {

      const winnerPrizeEth = web3.utils.fromWei(values.winnerPrize, 'ether')
      setTotalWin(Number(winnerPrizeEth).toFixed(4))
      setTotalMatched(values.totalMatch)
      setBlockHash(values.blockHash)
      setGuesserHash(values.guess)
      trackEvent("guess_completed_result", {totalMatched: String(values.totalMatch), 
                                            blockHash: String(values.blockHash)})
    }

    hashGuessers.onAvailablePrizesUpdated = setPrizes
    hashGuessers.onMaxPrizeUpdated = setTotalPrize
    hashGuessers.onEventsUpdated = setEvents
  }

  let bindWalletEvents = () => {

    let disconnectRoutine = () => {
      localStorage.clear()
      setPrizes(null)
      setWalletConnected(false)
      setWalletIcon(null)
      setWalletName("")
      setTotalPrize("")
      hashGuessers.kill()
    }

    wallet.onDisconnect = (error, reason) => { 

      disconnectRoutine() 

      let reasonMessage = "Some reason"

      if(reason.params && reason.params.length > 0 ) {

        reasonMessage = reason.params.map(item => item.message).join(" | ")
      }

      trackEvent("wallet_disconnected", {reason: reasonMessage})

      if(reasonMessage.includes("Session Rejected")) {
        
        window.location.reload();
      }
    }
    wallet.onConnectionFailure = () => { 
      
      disconnectRoutine() 
      trackEvent("wallet_connection_failure")
      window.location.reload();
    }

    wallet.onConnect = onWalletConnected

    wallet.onSessionUpdate = (error, payload) => {

      if (error) { return }

      setWalletIcon(wallet.icon)
      setWalletName(wallet.name)
      trackEvent("wallet_session_update", {walletName: wallet.name})
    }
  }

  useEffect(() => { 

    wallet = new Wallet(props.chainId, props.chainParam.rpcUrls[0])
    web3 = wallet.web3
    bindWalletEvents()

    hashGuessers = new HashGuessers(props.contractAddress, web3)
    bindHashGuessersEvents()

    const app = initializeApp(firebaseConfig);
    analytics = getAnalytics(app);

    if(wallet.connected) {
      
      onWalletConnected()
    }

  }, [])

  return (
    <div className="App">
      <div className='Fullscreen animated-gradient'/>
      <div className='Fullscreen PatternBkg'/>
      <Container className='Container'>
        <ConnectWalletRowView 
          walletConnected={walletConnected}
          chainParam={props.chainParam}
          walletIcon={walletIcon}
          walletName={walletName}
          expectedChainId={props.chainId}
          currentChainId={wallet?.chainId}
          onConnectWallet={() => {

            if (walletConnected == false) {
              trackEvent("connect_wallet")
              wallet.connect()
            } else {
              trackEvent("disconnect_wallet")
              wallet.disconnect()
            }
          }}
          onSwitchOrAddChainClicked={() => switchOrAddChain(props.chainParam).catch(console.error)} />
        <MaxPrizeRowView id={"top-banner"} totalPrize={totalPrize} />
        <FailureAlertView 
          betFailed={betFailed}
          onClose={() => {

            trackEvent("close_failure_view")
            setBetFailed(false)
          }}
          transactionExplorer={transactionExplorer}
        />
        <ProcessingAlertView
          betConfirming={betConfirming}
          transactionExplorer={transactionExplorer}
        />
        <ConfirmationAlertView 
          betConfirmed={betConfirmed} 
          guesserHash={guesserHash}
          blockHash={blockHash}
          totalWin={totalWin}
          transactionExplorer={transactionExplorer}
          receipt={receipt}
          totalMatched={totalMatched}
          onClose={() => { 
            setTransactionExplorer("#")
            trackEvent("close_confirmation_view")
            setBetConfirmed(false)}}
          onGuessAgain={async () => {
            setSelectedValues(guesserHash);
            document.getElementById('top-banner').scrollIntoView();
            trackEvent("start_guess", {again: true})
            await guess(fromAddress)
          }}  />
        <SelectedDigitsView selectedValues={selectedValues} />
        <KeyBoardView 
          maxSelectedValues={MAX_SELECT_NUMBERS} 
          selectedValues={selectedValues}
          allAvailableDigits={allAvailableDigits}
          onShuffle={(quantity) => trackEvent("shuffle_guess", {quantity})}
          onValue={setSelectedValues} />
        <ActionButtonsView
          maxSelectedValues={MAX_SELECT_NUMBERS} 
          selectedValues={selectedValues}
          allAvailableDigits={allAvailableDigits}
          onValue={setSelectedValues}
          onGuessClicked={async () => {
            let from = fromAddress

            if(!wallet.connected) {

              wallet.connect()
            }

            await guess(from)
          }} />
        <PrizesTableView prizes={prizes} web3={web3} />
        <HowItWorksView blockExplorer={blockExplorer} contractAddress={contractAddress} />
        <RulesView />
        <GuessEventsView events={events} />
        <ContactRowView />
      </Container>
    </div>
  );
}

export default App;
