import { Card} from 'react-bootstrap';

export default (props) => {

    return (<Card className="border shadow p-3 mb-1 bg-light rounded" style={{ width: '100%' }}>
            <Card.Text className='justify-content-start align-items-center rounded p-1 bg-dark text-white'>
                  📝 About HashGuessers
                </Card.Text>
                <Card.Text className='justify-content-start align-items-center p-1'>
                <b><a target="_blank" href={props.blockExplorer+"address/"+props.contractAddress}>HashGuessers' SmartContract</a></b> is fair. This game is automatic, which means it has no human interference. It also works instantly, so if you guess and match valid values, it will pay the prize on the same transaction.
                </Card.Text>
            </Card>)
  }