import { Card} from 'react-bootstrap';

export default () => {
    return (<Card className="border shadow p-3 mb-1 bg-light rounded" style={{ width: '100%' }}>
              <Card.Text className='justify-content-start align-items-center rounded p-1 bg-dark text-white'>
                🗃 Rules
              </Card.Text>
              <Card.Text className='justify-content-start align-items-center p-0'>
                The rules are simple, you have to choose six values between 0,1,2,3,4,5,6,7,8,9,a,b,c,d,e,f. If you guess more than two matches, no matter the order, you will win a prize based on the balance of the HashGuessers' SmartContract.
              </Card.Text>
            </Card>)
  }