import { Row, Col, Button } from 'react-bootstrap';

export default (props) => {

    var buttonLabel = props.selectedValues.length == 6 ? "Guess with " + props.selectedValues : "Select your guess 👆"

    return (<Row className="p-3">
                <Col className='d-grid gap-2' xs={true}>
                    <Button 
                        id="bet-button" 
                        size="lg" 
                        variant="info" 
                        disabled={props.selectedValues.length < props.maxSelectedValues} 
                        onClick={()=> props.onGuessClicked()}>
                            {buttonLabel}
                    </Button>
                </Col>
            </Row>)
}