
import { Button, Row, Stack, Image} from 'react-bootstrap';
import PolygonLogo from '../polygon_black_logo.svg';
import PolygonWhiteLogo from '../polygon_white_logo.svg';

export default (props) => {

    let buttonConnectWalletLabel = "Connect wallet"
    let walletIconImage = null
    let buttonVariant = "info"
    let polygonLogo = PolygonLogo
  
    if(props.walletConnected) {
      walletIconImage = <Image width={50} height={50} src={props.walletIcon}/>
      buttonConnectWalletLabel = "Disconnet from "+props.walletName
      buttonVariant = "secondary"
      polygonLogo = PolygonWhiteLogo
    }

    let addChainNetwork = null
    // if(window.ethereum && window.ethereum.chainId != props.chainParam.chainId) {
  
    //   addChainNetwork = <Button variant="dark" onClick={props.onSwitchOrAddChainClicked}>
    //                       {"Add "}<img height="24px" src={PolygonLogo} alt="Polygon Logo" />
    //                     </Button>
  
    // }


    return (<Row className="p-4 d-flex justify-content-center">
            <div className="ms-auto">
              <Stack direction="horizontal" gap={3}>
                
                  {addChainNetwork}
                  <Button variant={buttonVariant} onClick={props.onConnectWallet}>
                  
                  {buttonConnectWalletLabel}
                  <img className='px-2' height="24px" src={polygonLogo} alt="Polygon Logo" />
                  </Button>
                  {walletIconImage}
              </Stack>
            </div>
        </Row>)
}