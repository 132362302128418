import SmartContract from './smartcontract.json'

export default class HashGuessers {

    constructor(address, web3) {

        this.web3 = web3
        this.contract = new web3.eth.Contract(SmartContract, address)

        this.minConfirmationNumber = 1

        this.onProcessing = (transaction) => {}
        this.onError = (error) => {}
        this.onCompleted = (receipt) => {}
        this.onMaxPrizeUpdated = (value) => {}
        this.onAvailablePrizesUpdated = (values) => {}
        this.onEventsUpdated = (values) => {}
        this.onGuessResultUpdated = (values) => {}  
    }

    initialize = (fromAddress) => {

        if(this.fromAddress) { return }

        this.fromAddress = fromAddress
        this.update()
        this.updateInterval = setInterval(this.update, 5000)
    }

    update = () => {

        this.updateEvents().catch(console.error)
        this.updatePrizeInfo().catch(console.error)
    }

    kill = () => {
        
        if(this.updateInterval) {

            clearInterval(this.updateInterval)
        }

        this.fromAddress = null
    }

    guess = async (guessValues) => {

        if(!this.fromAddress) { 

            console.error("Should call initialize before guess")
        }

        const value = await this.contract.methods.guessPrice().call()
        const gas = 3000000

        let signedTxnListener = this.contract.methods
        .guess(guessValues)
        .send({from: this.fromAddress, value, gas})
        .on('transactionHash', (transactionHash) => {

            signedTxnListener.off('transactionHash')
            this.onProcessing(transactionHash)
        })
        .on('confirmation', async (confirmationNumber, receipt) => { 

            if(confirmationNumber <= this.minConfirmationNumber) {

                return
            }
            
            await this.updateEvents()
            this.onGuessResultUpdated(this.lastAddressEvent)
            this.onCompleted(receipt)
            signedTxnListener.off('confirmation')
            signedTxnListener.off('error')
            signedTxnListener = null
        })
        .on('error', (error)  => {

            signedTxnListener.off('confirmation')
            signedTxnListener.off('error')
            signedTxnListener = null
            this.onError(error)
        });

        await signedTxnListener
    }

    convertToEther = (value) => {
        
        const potEther = this.web3.utils.fromWei(value, 'ether')
        return Number(potEther).toFixed(4)
    }

    updatePrizeInfo = async () => {

        if(!this.contract) { return }

        const prizes = await this.contract.methods.prizes().call()
        const maxWin = prizes[prizes.length-1]

        this.onMaxPrizeUpdated(this.convertToEther(maxWin))
        this.onAvailablePrizesUpdated(prizes)
    }

    lastEvents = async () => {

        if(!this.web3 || !this.web3.eth) { return }
    
        let fromBlock = await this.web3.eth.getBlockNumber()-1000;
    
        let pastEvents = await this.contract.getPastEvents('GUESS', {
          filter: {},
          fromBlock: fromBlock,
          toBlock: 'latest'
        })
    
        if(pastEvents.length == 0) { return }
    
        return pastEvents
        .reverse()
        .map( item => item.returnValues )
        .filter( item => item.guesser)
    }

    updateEvents = async () => {

        let events = await this.lastEvents()

        if(!events || events.length == 0) { return }

        this.lastAddressEvent = events.find( item => item.guesser.toLowerCase() == this.fromAddress.toLowerCase())
        
        const allEventsArray = events.map( item => {
          let prize = this.convertToEther(item.winnerPrize)
          return [
            item.guesser.replace(/^(.{4}).{6,}.(.{3})/, "$1…$2"),
            item.guess,
            item.blockHash,
            item.totalMatch,
            prize
          ]
        }).slice(0, 30)
    
        if(allEventsArray.length > 0) { 
            
            this.onEventsUpdated(allEventsArray)
        } 
    }
}