import { Card, Table } from 'react-bootstrap';

export default (props) => {

    if(!props.events || props.events.length == 0) { return null }

    return (<Card className="border shadow p-3 mb-1 bg-light rounded" style={{ width: '100%' }}>
            <Card.Text className='justify-content-start align-items-center rounded p-1 bg-dark text-white'>
                📡 Recent Guesses
            </Card.Text>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Wallet</th>
                  <th>Guess</th>
                  <th>Hash</th>
                  <th>Match</th>
                  <th>prize</th>
                </tr>
              </thead>
              <tbody>
                {
                  props.events.map( item => (<tr key={item[2]}>
                                                <td>{item[0]}</td>
                                                <td>{item[1]}</td>
                                                <td>{item[2]}</td>
                                                <td>{item[3]}</td>
                                                <td>{item[4]}</td>
                                            </tr>))
                }
                
              </tbody>
            </Table>
            </Card>)
}