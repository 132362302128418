import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));

const networksParams = {
  polygon: {
    chainParam: {
      chainId: '0x89',
      chainName: 'Matic Mainnet',
      nativeCurrency: {
          name: 'MATIC',
          symbol: 'MATIC',
          decimals: 18
      },
      rpcUrls: ['https://polygon-rpc.com/'],
      blockExplorerUrls: ['https://polygonscan.com/']
    },
    chainId: 137,
    contractAddress: "0x5Cb29Af7f50975c11c81d5C7e11049b201b3DF2b"
  },
  mumbai: {
    chainParam: {
      chainId: '0x13881',
      chainName: 'Polygon Testnet',
      nativeCurrency: {
          name: 'MATIC',
          symbol: 'MATIC',
          decimals: 18
      },
      rpcUrls: ['https://matic-mumbai.chainstacklabs.com'],
      blockExplorerUrls: ['https://mumbai.polygonscan.com/']
    },
    chainId: 80001,
    contractAddress: "0xa5eb041522af752A2059Ccf690fAf3055fab9C1A"
  }
}

function AppConfig() {
  // Get the userId param from the URL.
  let { networkAlias } = useParams();
  networkAlias = !networkAlias ? "polygon" : networkAlias
  console.log(networkAlias)
  const networkData = networksParams[networkAlias]
  console.log(networkAlias, networkData)
  return <App 
          chainParam={networkData.chainParam} 
          chainId={networkData.chainId}
          contractAddress={networkData.contractAddress}
        />
}

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={ <AppConfig />} />
      <Route path=":networkAlias" element={ <AppConfig />} />
    </Routes>
  </BrowserRouter>  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
