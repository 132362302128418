import { Row, Col, Card } from 'react-bootstrap';

export default (props) => {

    let value = props.selectedValues == "" ? <h2>Good luck! 🍀</h2> : props.selectedValues.split("").map( (value, index) => { 
        return <Col className='d-grid gap-2' xs={2} key={index.toString() } >
                    <p class="h2"> {value} </p>
                </Col>
        })

    let footer = props.selectedValues == "" ? <p>Select here 👇</p> : <p>Good luck! 🍀</p>

    return (<Card className="border shadow p-3 mb-1 bg-light rounded">
                <Row className='p-4 align-items-center'>
                {value}
                </Row>
                <Row className='pt-1 align-items-center'>
                {footer}
                </Row>
            </Card>)
}